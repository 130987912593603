import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FaBinoculars } from "react-icons/fa";
import Nav from "react-bootstrap/esm/Nav";
import { FormataValor } from "../k_functions.js";
import Alert from 'react-bootstrap/Alert';

class ListarVendasEmAberto extends Component {
  state = {
    aVendas: [],  // contém a lista de vendas filtrados
    aVendasAll: []  // contém todos as vendas
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasListar.php",
        func_trata: "VendasListar",
        dat_ini: "",
        dat_fim: "",
        cod_cli: "",
      }
    })
    .then((response) => {
//      console.log(response.data);
      this.setState({ aVendas: response.data });
      this.setState({ aVendasAll: response.data });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    return (
      <>
        <CabPage titulo="Vendas em Andamento" paginavoltar="/home" />
        {this.state.aVendas.msg === 'ERRO' &&
          <Alert variant="warning">Não há vendas em andamento !</Alert>
        }
        {this.state.aVendas.length > 0 && 
          <Table striped bordered hover>
            <thead>
              <tr align='center'>
                <th width="15%">#Venda<br/>Usuário</th>
                <th width="25%">Data<br/>Hora</th>
                <th width="30%">Cliente</th>
                <th width="30%" colSpan={2}>Total</th>
              </tr>
            </thead>
            <tbody>
            {this.state.aVendas.map((oVenda, index) => (
              <tr key={index}>
                <td>{oVenda.id_venda}<br/>{oVenda.login}</td>
                <td>{oVenda.data_lan_venda}<br/>{oVenda.hora_lan_venda}</td>
                <td>{oVenda.fantasia}</td>
                <td>{FormataValor(parseFloat(oVenda.valor_total))}</td>
                  <td><Nav activeKey="/vender">
                    <Nav.Link href={`/vender/${oVenda.id_venda}`}>
                      <FaBinoculars />
                    </Nav.Link>
                  </Nav>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        }
      </>
    );
  }
}

export default ListarVendasEmAberto;