import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { FaBinoculars } from "react-icons/fa";
import Nav from "react-bootstrap/esm/Nav";
import { FormataValor } from "../k_functions.js";

class ListaEstoqueRet extends Component {
  state = {
    aEstRetirar: [],  // contém a lista de vendas filtrados
    aEstRetirarAll: []  // contém todos as vendas
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Estoque/ARetirar.php",
        func_trata: "EstoqueARetirar"
      }
    })
    .then((response) => {
      this.setState({aEstRetirar: response.data});
      this.setState({aEstRetirarAll: response.data});
//      console.log(response.data);
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  // filtra dados conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aEstRetirar = this.state.aEstRetirarAll.filter(el => 
      ((el.nome_razao.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.desc_produto.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_venda.toString().indexOf(e.target.value) > -1) ||
       (el.nome.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)));
    this.setState({aEstRetirar: aEstRetirar});
  }
  
  render() {
    return (
      <>
        <CabPage titulo="A Retirar" paginavoltar="/home" />
        <div className="App-body">
          {(this.state.aEstRetirar.length === 0 || this.state.aEstRetirar.msg === 'ERRO') && 
              <Alert variant="warning">Não há produtos a retirar !</Alert>
          }
          {(this.state.aEstRetirar.length > 0 && this.state.aEstRetirar.msg !== 'ERRO') && 
          <>
            <form>
              <input className="form-control mr-sm-2" type="search" placeholder="Localizar" aria-label="Search" onChange={this.handleLocalizar} />
            </form>
            <Table striped bordered hover>
                <thead>
                  <tr align='center'>
                    <th width="33%">Produto<br/>Unidade</th>
                    <th width="32%">Cliente<br/>Vendedor</th>
                    <th width="25%">Venda<br/>Data</th>
                    <th width="10%">Qtd<br/>Lançar</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.aEstRetirar.length > 0 && 
                  this.state.aEstRetirar.map((oEstRetirar, index) => (
                  <tr key={index}>
                  <td>{oEstRetirar.desc_produto}<br/>
                      {oEstRetirar.desc_unidade}</td>
                  <td>{oEstRetirar.fantasia}<br/>
                      {oEstRetirar.nome.substring(0, 
                        (oEstRetirar.nome.indexOf(' ') === -1) ? oEstRetirar.nome.length : oEstRetirar.nome.indexOf(' '))}</td>
                  <td>{oEstRetirar.id_venda}<br/>{oEstRetirar.data_lan_venda}</td>
                  <td>{FormataValor(parseFloat(oEstRetirar.qtd_pendente))}<br/>
                      <Nav activeKey="/estent">
                        <Nav.Link href={`/estent/${oEstRetirar.id_venda}`}>
                          <FaBinoculars />
                        </Nav.Link>
                      </Nav>
                  </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </>
          }
      </div>
      </>
    );
  }
}
export default ListaEstoqueRet;