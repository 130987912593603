import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FormataValor } from "../k_functions.js";
//import { GetDataBDViaAxios } from "../k_functions.js";

class SituacaoEstoque extends Component {
  state = {
    aEstoque: [],
    aEstoqueAll: []
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
/*
    let aResponse = GetDataBDViaAxios ("./Estoque/Situacao.php", "Situacao");
//    this.setState({ aEstoque: GetDataBDViaAxios ("./Estoque/Situacao.php", "Situacao")});
    this.setState({ aEstoque: aResponse});
    console.log(this.state.aEstoque);
*/    
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Estoque/Situacao.php",
        func_trata: "Situacao"
      }
    })
      .then((response) => {
        // console.log(response.data);
        if (Array.isArray(response.data))
        {
          this.setState({ aEstoque: response.data });
          this.setState({ aEstoqueAll: response.data });
        }
        else
        {
          console.log(response.data); 
          alert(response.data);
        }
      })
      .catch(function (error) { console.log(error); alert(error) })
  }
  
  // filtra produtos conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aEstoque = this.state.aEstoqueAll.filter(el => 
      ((el.desc_produto.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.desc_unidade.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_produto.toString().indexOf(e.target.value) > -1)));
    this.setState({aEstoque: aEstoque});
  }
  
  render() {
    return (
      <>
        <CabPage titulo="Situação do Estoque" paginavoltar="/home" />
        <form>
          <input className="form-control mr-sm-2" type="search" placeholder="Localizar" aria-label="Search" onChange={this.handleLocalizar} />
        </form>
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="40%">Produto<br/>Unid</th>
              <th width="15%">$<br/>Sugerido</th>
              <th width="15%">Qtd<br/>Disp</th>
              <th width="15%">Qtd<br/>Pend</th>
              <th width="15%">Qtd<br/>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.state.aEstoque.map((oEstoque, index) => (
              <tr key={index} align='center'>
                <td>{oEstoque.desc_produto}<br/>{oEstoque.desc_unidade}</td>
                <td>{FormataValor(parseFloat(oEstoque.valor_unit_sugerido))}</td>
                <td>{oEstoque.qtd_disponivel}</td>
                <td>{oEstoque.qtd_a_retirar}</td>
                <td>{oEstoque.qtd_est_total}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default SituacaoEstoque;