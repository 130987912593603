import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";

class UsuariosListar extends Component {
  state = {
    aUsuarios: []
  };

  /* requisição com axios para ler lista de produtos */
  async componentDidMount() {
    axios({
      method: 'get',
//      url: 'http://localhost:8080/React/ctc3/src/server/TrataGedtPost.php',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Usuarios/UsuariosListar.php",
        func_trata: "UsuariosListar"
      }
    })
      .then((response) => {
//        console.log(response.data);
        this.setState({ aUsuarios: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  render() {
    return (
      <>
        <CabPage titulo="Usuários" paginavoltar="/home" />
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="10%">ID</th>
              <th width="20%">Login</th>
              <th width="50%">Nome</th>
              <th width="10%">Ativo</th>
              <th width="10%">Full</th>
            </tr>
          </thead>
          <tbody>
            {this.state.aUsuarios.map((oUsuario, index) => (
              <tr key={index} align='center'>
                <td>{oUsuario.id_usuario}</td>
                <td>{oUsuario.login}</td>
                <td>{oUsuario.nome}</td>
                <td>{oUsuario.ativo}</td>
                <td>{oUsuario.acessofull}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default UsuariosListar;