import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import { BsPencilFill } from "react-icons/bs"
import { FormataValor, trataAxios } from "../k_functions.js";

class PrecosDetalhe extends Component {
  state = {
    aDetPrecos: [],
    nPreco: 0
  };

  /* requisição com axios para ler detalhe do precos */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Precos/PrecosDetalhe.php",
        func_trata: "PrecosDetalhe",
        id_preco: this.props.idPreco
      }
    })
      .then((response) => {
//        console.log(response.data);
        this.setState({ aDetPrecos: response.data });
        this.setState({ nPreco: response.data[0].valor_unit_sugerido });

      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  handleChangePreco = () => e => {  
//    console.log (e);
    let nPreco = e.target.value;
    this.setState({ nPreco });
  };


  // chamado ao clicar no botão de alterar ou eliminar
  handleSubmit = (event) => {
    let isConfirmed = true;
    let sMess = "Confirma a exclusão do preço ?";
    let oDadosPrec = {};

    if (event.nativeEvent.submitter.id !== "bt_del")
      sMess = "Confirma a alteração do preço ?";
    if (! window.confirm(sMess))
    {
      event.preventDefault();
      event.stopPropagation();
      isConfirmed = false;
    } 
   
    // se os dados são válidos e confirmou a movimentação
    if (isConfirmed)
    {
      if (event.nativeEvent.submitter.id === "bt_del")
      {
        // chama o programa php para excluir o preço
        oDadosPrec = {
          // id do preço
          id_preco: this.props.idPreco,
          // programa e funcao que vao salvar os dados
          prog_trata: "./Precos/PrecosExcluir.php",
          func_trata: "ExcluiPreco"
        };
      }
      else
      {
        // chama o programa php para excluir o preço
        oDadosPrec = {
          // id do preço
          id_preco: this.props.idPreco,
          preco: this.state.nPreco,
          // programa e funcao que vao salvar os dados
          prog_trata: "./Precos/PrecosAlterar.php",
          func_trata: "AlteraPreco"
        };
      }

//      console.log(dadosPrec);
      trataAxios(oDadosPrec);
    }
  };

  render() {
    return (
      <>
        <CabPage titulo="Preço" paginavoltar="/prelis" />
        <div className="App-body">
          <form onSubmit={this.handleSubmit} action="/prelis">
            <FloatingLabel controlId="floatingTotal" label="Produto">
              <Form.Control type="text" value={this.state.aDetPrecos.length > 0 ? this.state.aDetPrecos[0].desc_produto : ""} disabled/>
            </FloatingLabel>
            <br/>
            <FloatingLabel controlId="floatingTotal" label="Unidade">
              <Form.Control type="text" value={this.state.aDetPrecos.length > 0 ? this.state.aDetPrecos[0].desc_unidade : ""} disabled/>
            </FloatingLabel>
            <br/>
            <FloatingLabel controlId="floatingTotal" label="Preço">
              <Form.Control type="number" 
                value={this.state.aDetPrecos.length > 0 ? this.state.nPreco : ""} 
                onChange={this.handleChangePreco()}
              />
            </FloatingLabel>
            <br/>
            <center>
              <Button as="button" type="submit" id="bt_upd" 
                variant="success" size="lg">Atualizar preço
              </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button as="button" type="submit" id="bt_del" 
                variant="danger" size="lg">Eliminar preço
              </Button>
            </center>
          </form>
        </div>
      </>
    );
  }
}

export default PrecosDetalhe;