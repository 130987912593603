import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { FormataValor, FormataData } from "../k_functions.js";

class FinancRelRes extends Component {
  state = {
    aFinancCxF: [], // total de parcelas por cliente e forma
    aFinancCli: [], // total de parcelas por cliente
    aFinancFor: [], // total de parcelas por forma
    aFinancDia: [], // total de parcelas por dia
    aFinancTot: []  // total de parcelas conforme filtros selecionados
  };

  /* requisição com axios para ler lista de parcelas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Financas/FinancasRelat.php",
        func_trata: "FinancasRelat",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        cod_for: this.props.cFor,
        cod_cli: this.props.cCli,
        tip_sit: this.props.cSit,
      }
    })
    .then((response) => {
      //alert(response.data);
//      console.log(response.data);
      this.setState({ aFinancCxF: response.data[0] });  // total por cliente e forma
      this.setState({ aFinancCli: response.data[1] });  // total por cliente
      this.setState({ aFinancFor: response.data[2] });  // total por forma
      this.setState({ aFinancDia: response.data[3] });  // total por dia 
      this.setState({ aFinancTot: response.data[4] });  // total geral
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    let k=0;
    return (
      <>
        <CabPage  titulo="Relatório Financeiro" 
                  subtitulo={"Vencimento: " + FormataData(this.props.dIni) + " - " + FormataData(this.props.dFim)}
                  paginavoltar="/finrel" 
                  classSubTit="SubTitRel" />
        {this.state.aFinancCxF.length === 0 && 
          <Alert variant="warning">Não há parcelas para listar !</Alert>
        }
        {this.state.aFinancCli.length > 0 && this.props.cTCl === "S" && 
          <>
            <b>Total por Cliente</b>
            <Table striped bordered hover>
              <thead>
                <tr key={1000}>
                  <th width="50%">Cliente</th>
                  <th width="25%">$ Original</th>
                  <th width="25%">$ Quitado</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aFinancCli.map((oParcela, index) => (
                  <>
                    <tr key={3000+index}>
                      <td>{oParcela.fantasia}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValPar*1))}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValQui*1))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aFinancFor.length > 0 && this.props.cTFo === "S" && 
          <>
            <b>Total por Forma</b>
            <Table striped bordered hover>
              <thead>
                <tr key={1000}>
                  <th width="50%">Forma</th>
                  <th width="25%">$ Original</th>
                  <th width="25%">$ Quitado</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aFinancFor.map((oParcela, index) => (
                  <>
                    <tr key={3000+index}>
                      <td>{oParcela.desc_forma}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValPar*1))}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValQui*1))}</td>
                    </tr>
                  </>
              ))} 
              </tbody>
            </Table>
          </>
        }
        {this.state.aFinancCxF.length > 0 && this.props.cTCF === "S" && 
          <>
            <b>Total por Cliente e Forma</b>
            <Table striped bordered hover>
              <thead>
                <tr key={1000}>
                  <th width="50%">Cliente / Forma</th>
                  <th width="25%">$ Original</th>
                  <th width="25%">$ Quitado</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aFinancCxF.map((oParcela, index) => (
                <>
                  {index === 0 || oParcela.id_cliente !== this.state.aFinancCxF[index-1].id_cliente ? 
                    <tr key={1000+index}><td colSpan={3}><b>{oParcela.id_cliente} - {oParcela.fantasia}</b></td></tr> : ""}
                  <tr key={2000+index}>
                    <td>{oParcela.desc_forma}</td>
                    <td align='right'>{FormataValor(parseFloat(oParcela.ValPar*1))}</td>
                    <td align='right'>{FormataValor(parseFloat(oParcela.ValQui*1))}</td>
                  </tr>
                </>
              ))}
              </tbody>
            </Table>
          </>
        }
        {this.state.aFinancDia.length > 0 && this.props.cTDi === "S" && 
          <>
            <b>Total por Dia</b>
            <Table striped bordered hover>
              <thead>
                <tr key={5000}>
                  <th width="50%">Vencimento</th>
                  <th width="25%">$ Original</th>
                  <th width="25%">$ Quitado</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.aFinancDia.map((oParcela, index) => (
                  <>
                    <tr key={(index+5000)}>
                      <td>{FormataData(oParcela.data_vencim)}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValPar*1))}</td>
                      <td align='right'>{FormataValor(parseFloat(oParcela.ValQui*1))}</td>
                    </tr>
                  </>
              ))}
              </tbody>
            </Table>
          </>
        }
        <b>Total das Parcelas: {FormataValor(parseFloat(this.state.aFinancTot[0]))}</b><br/>
        <b>Total Quitado: {FormataValor(parseFloat(this.state.aFinancTot[1]))}</b>
      </>
    );
  }
}

export default FinancRelRes;