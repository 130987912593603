import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Button from "react-bootstrap/esm/Button";
import Nav from "react-bootstrap/esm/Nav";
import { FormataValor } from "../k_functions.js";
import { FaBinoculars } from "react-icons/fa";

class PrecosListar extends Component {
  state = {
    aPrecos: [],    // contém os precos filtrados
    aPrecosAll: []  // contém todos os precos
  };

  /* requisição com axios para ler lista de precos */
  // usada a view de saldos de estoque pois ela contem ie e descrição tanto da unidades
  // quanto dos produtos, e tb o valor sugerido; assume-se que ela sempre trará pelo menos
  // uma linha por produto, tendo ou não saldo em estoque
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Estoque/Situacao.php",
        func_trata: "Situacao"
      }
    })
    .then((response) => {
//      console.log(response.data);
      this.setState({ aPrecos: response.data });
      this.setState({ aPrecosAll: response.data });
    })
    .catch(function (error) { console.log(error); alert(error) })
  }

  // filtra produtos conforme usuário vai digitando no campo filtrar
  handleLocalizar = (e) => {
    const aPrecos = this.state.aPrecosAll.filter(el => 
      ((el.desc_produto.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.desc_unidade.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_produto.toString().indexOf(e.target.value) > -1)));
    this.setState({aPrecos: aPrecos});
  }

  render() {
    let acessofull = localStorage.getItem("acessofull");
    return (
      <>
        <CabPage titulo="Preços" paginavoltar="/home" />
        <form>
          <input className="form-control mr-sm-2" type="search" placeholder="Localizar" aria-label="Search" onChange={this.handleLocalizar} />
        </form>
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="35%">Produto</th>
              <th width="30%">Unidade</th>
              <th width="35%">$ Sugerido&nbsp;</th>
              {acessofull === 'S' &&
                <th>
                  <Nav activeKey="/preadd">
                    <Nav.Link href={`/preadd`}>
                    <Button as="button" id="bt_add" variant="success" size="sm">+</Button>
                    </Nav.Link>
                  </Nav>
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {this.state.aPrecos.map((oPreco, index) => (
              <tr key={index} align='center'>
                <td>{oPreco.desc_produto}</td>
                <td>{oPreco.desc_unidade}</td>
                <td>{FormataValor(parseFloat(oPreco.valor_unit_sugerido))}</td>
                {acessofull === 'S' &&
                  <td>
                    <Nav activeKey="/predet">
                      <Nav.Link href={`/predet/${oPreco.id_prod_unid}`}>
                        <FaBinoculars />
                      </Nav.Link>
                    </Nav>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default PrecosListar;