import React, { Component } from "react";
import CabPage from "../CabPage";
import VenderCli from "./VenderCli";
import VenderPro from "./VenderPro";
import VenderCar from "./VenderCar";
import VenderPag from "./VenderPag";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { LeProdUnidPrec } from "../k_functions.js";

class Vender extends Component {
  state = { 
    aProds: [], aProdsIdx: [], 
    aUnids: [], aUnidsIdx: [], 

    iContItens: 1,  // contador dos itens de venda

    // aCarrinho na verdade deveria se chamar oCarrinho, pois na verdade é mais um objeto do que um array
    aCarrinho: {
      // total acumulado da venda (seria o subtotal da venda, antes do desconto)
      nTotAcc: 0,

      // percentual do desconto do cliente no valor final da venda
      // vem do cadastro do cliente e pode ser alterado / inputado na tela de pagamentos
      nPercDescon: 0,

      // valor do desconto a ser aplicado no nTotAcc
      nDescon: 0,

      // aItens contém a relação de itens selecionados para venda
      // com os seguintes atributos:
      // item: numero do item (1, 2, 3...); 
      // prod: código do item (posição no array)
      // codp: código do item (banco de dados)
      // unid: código da unidade (posição no array)
      // codu: código da unidade (banco de dados)
      // vuni: valor unitário
      // qtde: quantidade
      // vtot: valor total 
      // reti: indica se a retirada da mercadoria será imediata (default) ou não
      aItens: [], // itens da venda

      // sCliente é o nome fantasia do cliente selecionado pelo usuário
      sCliente: (this.props.nCliente === 0 ? "****** Escolha o cliente ******" : this.props.sNome),

      // sRazClie é a razão social do cliente selecionado pelo usuário
      sRazClie: (this.props.nCliente === 0 ? "" : this.props.sNome),

      // nCliente é o id do cliente selecionado pelo usuário
      nCliente: this.props.nCliente,    // nCliente setado em App.js

      // TipoCli é o tipo do cliente, podendo ser: F p/ pessoa fisica, J para pessoa juridica e C para consumidor
      cTipoCli: "",
    },

    // tentei fazer no aviao (???)
    // aParcelas criado aqui para ser usada quando o usuário vai dar continuidade numa venda eque já estava em andamento
    aParcelas: []
  }

  // chamada quando adiciona um item ao carrinho
  addItem = oItem => {
//    oItem.item = this.state.aCarrinho.aItens.length + 1;
    oItem.item = this.state.iContItens;
    this.setState({iContItens: this.state.iContItens + 1});

    // calcula o novo total acumulado
    let nNewTotAcc = this.state.aCarrinho.nTotAcc + Math.round(oItem.vuni * oItem.qtde * 100) / 100;
    const nNewPercDescon = this.state.aCarrinho.nPercDescon;  
    const nNewDescon = this.state.aCarrinho.nDescon;  
    const aNewItens = [...this.state.aCarrinho.aItens, oItem];
    const sNewCliente = this.state.aCarrinho.sCliente; 
    const sNewRazClie = this.state.aCarrinho.sRazClie;
    const nNewCliente = this.state.aCarrinho.nCliente; 
    const sNewTipoCli = this.state.aCarrinho.cTipoCli;
    // atualiza a variável aCarrinho
    this.setState({
      aCarrinho: {
        nTotAcc: nNewTotAcc,
        nPercDescon: nNewPercDescon,
        nDescon: nNewDescon, 
        aItens: aNewItens, 
        sCliente: sNewCliente, 
        sRazClie: sNewRazClie, 
        nCliente: nNewCliente, 
        cTipoCli: sNewTipoCli}
    });
//    console.log(this.state.aCarrinho);
  };

  // remove um item do carrinho
  delItem = oItem => {
    if (window.confirm("Remover o item " + this.state.aProds[oItem.prod].desc + "?")) {
      const aNewItens = this.state.aCarrinho.aItens.filter(p => p.item !== oItem.item);
      const nNewTotAcc = this.state.aCarrinho.nTotAcc - Math.round(oItem.vuni * oItem.qtde * 100) / 100;
      const sNewCliente = this.state.aCarrinho.sCliente; 
      const nNewCliente = this.state.aCarrinho.nCliente; 
      const aCarrinho = {
        nTotAcc: nNewTotAcc, 
        aItens: aNewItens, 
        sCliente: sNewCliente, 
        nCliente: nNewCliente};
      this.setState({aCarrinho: aCarrinho});
      // zera as parcelas qdo exclui item
      this.setState({aParcelas: []});
    }
  };

  // marca ou desmarca o item para retirada imediata, ao clicar no flag Retirar Já na aba Carrinho
  retItem = (index) => {
    let  aNewItens = this.state.aCarrinho.aItens; 
    aNewItens[index].reti = ! (aNewItens[index].reti);
    const nNewTotAcc = this.state.aCarrinho.nTotAcc;
    const sNewCliente = this.state.aCarrinho.sCliente; 
    const nNewCliente = this.state.aCarrinho.nCliente; 
    const aCarrinho = {
      nTotAcc: nNewTotAcc, 
      aItens: aNewItens, 
      sCliente: sNewCliente, 
      nCliente: nNewCliente};
    this.setState({aCarrinho: aCarrinho});
  };

  // seta o cliente para o qual a venda está sendo feita
  // chamada quando seleciona um cliente na VenderCli
  setCliente = oCliente => {
//    console.log(oCliente);
    const nNewTotAcc = this.state.aCarrinho.nTotAcc;
    const nNewPercDescon = oCliente.perc_desc_redid;  
    const aNewItens = this.state.aCarrinho.aItens;
    const sNewCliente = oCliente.fantasia;
    const sNewRazClie = oCliente.nome_razao ;
    const nNewCliente = oCliente.id_cliente;
    const cNewTipoCli = oCliente.tipo_cliente;
    const aNewCarrinho = {
      nTotAcc: nNewTotAcc, 
      nPercDescon: nNewPercDescon,
      nDescon: 0, 
      aItens: aNewItens, 
      sCliente: sNewCliente, 
      sRazClie: sNewRazClie, 
      nCliente: nNewCliente, 
      cTipoCli: cNewTipoCli};
    this.setState({aCarrinho: aNewCarrinho});
    document.querySelector("#SubTitPage").classList.remove("SubTitCabPagYellow");  
    document.querySelector("#SubTitPage").classList.add("SubTitCabPag");
//    console.log(aNewCarrinho);
//    console.log(this.state.aCarrinho);
  }

  // 16/03/2024 - nao testado
  // seta venda, ou seja, cliente, produtos e parcelas, quando clica para continuar numa venda
  // tentei usar o additem, setcliente, mas nao estava conseguindo, entao criei esta nova funcao
  setVenda = (oVenda, oParcelas) => {
/*    
    console.log("setVenda(): ");
    console.log("oVenda => ");
    console.log(oVenda);
    console.log("oParcelas => ");
    console.log(oParcelas);
    console.log("state.aCarrinho antes => ");
    console.log(this.state.aCarrinho);
    
    let aNewItens = [];
    let nNewTotAcc = 0;

    // seta o cliente
    const sNewCliente = oVenda[0].fantasia;
    const sNewRazClie = oVenda[0].nome_razao;
    const nNewCliente = oVenda[0].id_cliente;
    const cNewTipoCli = oVenda[0].tipo_cliente;

    // seta valores totais
    const nNewDesc  = oVenda[0].valor_desconto;
    const nNewTotal = oVenda[0].valot_total;

    // seta os produtos
    oVenda.map((oVendaLin, index) => (
      aNewItens.push({
        codp: oVendaLin.id_produto, 
        codu: oVendaLin.id_unidade, 
        item: index,
        prod: "0",
        qtde: oVendaLin.qtd_vendida,
        reti: true,
        unid: "0", 
        vtot: Math.round(oVendaLin.valor_unit * oVendaLin.qtd_vendida * 100) / 100,
        vuni: oVendaLin.valor_unit
      })
    ))

    const aNewCarrinho = {
      nTotAcc: nNewTotAcc, 
      nDescon: nNewDesc, 
      aItens: aNewItens, 
      sCliente: sNewCliente, 
      sRazClie: sNewRazClie, 
      nCliente: nNewCliente, 
      cTipoCli: cNewTipoCli};
//    console.log("aNewCarrinho");
//    console.log(aNewCarrinho);
    this.setState({aCarrinho: aNewCarrinho});
    document.querySelector("#SubTitPage").classList.remove("SubTitCabPagYellow");  
    document.querySelector("#SubTitPage").classList.add("SubTitCabPag");
//    console.log("state.aCarrinho depois => ");
//    console.log(this.state.aCarrinho);

    /* tentei fazer no aviao
    // seta as parcelas
    const aNewParcelas = [];
    oParcelas.map((oParcelaLin, index) => (
      aNewParcelas.push({
        valor: oParcelaLin.valor_parcela, 
        forma: oParcelaLin.id_forma, 
        vencimento: oParcelaLin.dt_vcto
      })
    ))

    this.setState({aParcelas: [{valor: 1750, forma: 2, vencimento: '08/05/2023'}]});
    console.log("newparcelas");
    console.log(aNewParcelas);
    console.log("state aparcelas");
    console.log(this.state.aParcelas);
    */
  }

  // le dados de produtos, unidades, precos
  async componentDidMount() {
    let oPUP = LeProdUnidPrec();
    this.setState({aProds: oPUP.aProds, aUnids: oPUP.aUnids});

    /* parte nova para recuperar dados de vendas em aberto ***************************************************/
    /* 16/03/2024:ainda não totalmente testada */
    /*
    // se está chamando uma venda em aberto, le dados da venda e preenche 
    // as variaveis de controle
    if (this.props.idVenda > 0)
    {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}TrataGetPost.php`,
        params: {
          prog_trata: "./Vendas/VendasDetalhe.php",
          func_trata: "VendasDetalhe",
          id_venda: this.props.idVenda,
          capa_e_linha: "Y"
        }
      })
      .then((response) => {
        // response.data tem 2 elementos: 
        //  o 1o, acessado por .parcelas, é um array com 1 elemento por parcela
        //  o 2o, acessado por .venda, é um array com 1 elemento por produto vendido
        console.log("vou chamar");
        console.log(response.data);
        this.setVenda(response.data.venda, response.data.parcelas);
//        console.log(this.state.aCarrinho.aItens);
      })
      .catch(function (error) {console.log(error); alert(error)})
    }
    /* ate aqui parte nova *********************************************************** */
  }

  render() {
//    console.log(this.props);
    return (
      <>
      <CabPage titulo="Vender"
        subtitulo={(this.state.aCarrinho.nCliente > 0 ? this.state.aCarrinho.nCliente + " - " : "") + 
                   this.state.aCarrinho.sCliente} paginavoltar="/home" 
                   classSubTit={this.state.aCarrinho.nCliente > 0 ? "SubTitCabPag" : "SubTitCabPagYellow"} />
      <Tabs defaultActiveKey={this.state.aCarrinho.nCliente > 0 ? "cliente" : "cliente"} id="vender" className="sTabs" justify>
        <Tab eventKey="cliente" title="Cliente" tabClassName="sTab">
          <VenderCli aCarrinho={this.state.aCarrinho} rotSetCli={this.setCliente} />
        </Tab>
        <Tab eventKey="produtos" title="Produtos" tabClassName="sTab">
          <VenderPro aProds={this.state.aProds} aUnids={this.state.aUnids} aCarrinho={this.state.aCarrinho} rotAdd={this.addItem} />
        </Tab>
        <Tab eventKey="carrinho" title="Carrinho" tabClassName="sTab">
          <VenderCar aProds={this.state.aProds} aUnids={this.state.aUnids} aCarrinho={this.state.aCarrinho} rotDel={this.delItem} rotRet={this.retItem} />
        </Tab>
        <Tab eventKey="pagamento" title="Pagto" tabClassName="sTab">
          { // csr 20240302.ini
            // <VenderPag aCarrinho={this.state.aCarrinho} nUsuario={this.props.nUsuario} aParcelas={[]} /> 
          }
          <VenderPag aCarrinho={this.state.aCarrinho} nUsuario={this.props.nUsuario} aParcelas={this.state.aParcelas} /> 
          { 
            // csr 20240302.fim 
          }
        </Tab>
      </Tabs>
      </>
    )
  }
}

export default Vender;

/*
          <VenderPag aCarrinho={this.state.aCarrinho} nUsuario={this.props.nUsuario} aParcelas={[{valor: 1750, forma: 2, vencimento: '2023-05-08'}]} />
*/