import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from 'react-bootstrap/Form';
// import { BsPencilFill } from "react-icons/bs"
import Table from 'react-bootstrap/Table';
import { FormataValor } from "../k_functions.js";

class ProdutosDetalhe extends Component {
  state = {
    aDetProdutos: []
  };

  /* requisição com axios para ler lista de produtos */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Produtos/ProdutosDetalhe.php",
        func_trata: "ProdutosDetalhe",
        id_produto: this.props.idProduto
      }
    })
      .then((response) => {
        this.setState({ aDetProdutos: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  render() {
    return (
      <>
      <CabPage titulo="Produto" paginavoltar="/prolis" />
        <div className="App-body">
          <Row>
            <Col>
              <FloatingLabel controlId="floatingItens" label="ID">
                <Form.Control type="text" value={this.state.aDetProdutos.length > 0 ? this.state.aDetProdutos[0].id_produto : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingTotal" label="Descrição">
                  <Form.Control type="text" value={this.state.aDetProdutos.length > 0 ? this.state.aDetProdutos[0].desc_produto : ""} disabled/>
                </FloatingLabel>
            </Col>
          </Row>
          <br/>
          <b>ESTOQUE</b>
          <Table striped bordered hover size="sm">
            <thead>
              <tr align='center'>
                <th width="40%">Unidade</th>
                <th width="15%">Disponível</th>
                <th width="15%">A Retirar</th>
                <th width="15%">Total</th>
                <th width="15%">$ Sugerido</th>
              </tr>
            </thead>
            <tbody>
              {this.state.aDetProdutos.map((oDetProduto, index) => (
                <tr key={index} align='center'>
                  <td>{oDetProduto.desc_unidade}</td>
                  <td>{oDetProduto.qtd_disponivel}</td>
                  <td>{oDetProduto.qtd_a_retirar}</td>
                  <td>{oDetProduto.qtd_disponivel+oDetProduto.qtd_a_retirar}</td>
                  <td>{FormataValor(oDetProduto.valor_unit_sugerido)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default ProdutosDetalhe;