import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { FormataValor } from "../k_functions.js";
import Alert from 'react-bootstrap/Alert';

class ListarMovEstoqueResult extends Component {
  state = {
    aMovimentacao: []
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Estoque/MovEstListar.php",
        func_trata: "ListarMovEst",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim
      }
    })
      .then((response) => {
//        console.log(response.data);
        this.setState({ aMovimentacao: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  render() {
//    console.log(this.state.aMovimentacao);
    return (
      <>
        <CabPage titulo="Movimentações" subtitulo="Últimas 100 movimentações" paginavoltar="/home" classSubTit="sFonte6Branco"/>
        {this.state.aMovimentacao.msg === 'ERRO' &&
          <Alert variant="warning">Não há movimentações para listar !</Alert>
        }
        {this.state.aMovimentacao.length > 0 && 
          <>
          <Table striped bordered hover size="sm">
            <thead>
              <tr align='center'>
                <th width="15%">#Mov<br/><br/>Usuário</th>
                <th width="25%">Produto<br/><br/>Unidade</th>
                <th width="25%">#Venda<br/>Cliente<br/>Carregador</th>
                <th width="25%">Data<br/><br/>Hora</th>
                <th width="10%">Qtd<br/><br/>Tipo</th>
              </tr>
            </thead>
            <tbody>
              { this.state.aMovimentacao.map((oMovimentacao, index) => (
                <tr key={index} align='center'>
                  <td>{oMovimentacao.id_mov_estoque}<br/><br/>{oMovimentacao.login}</td>
                  <td>{oMovimentacao.desc_produto}<br/><br/>{oMovimentacao.desc_unidade}</td>
                  <td>{oMovimentacao.id_venda}<br/>{oMovimentacao.fantasia}<br/>{oMovimentacao.carregador != "" ? oMovimentacao.carregador : '-'}</td>
                  <td>{oMovimentacao.data_mov_est}<br/><br/>{oMovimentacao.hora_mov_est}</td>
                  <td>{FormataValor(parseFloat(oMovimentacao.qtd_mov_est))}<br/><br/>{oMovimentacao.id_transacao}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <i>Registros listados: {this.state.aMovimentacao.length}</i>
          </>
        }
      </>
    );
  }
}

export default ListarMovEstoqueResult;