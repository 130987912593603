import React, { Component } from "react";
import CabPage from "../CabPage";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Button from "react-bootstrap/esm/Button";
import { Separador, trataAxios } from "../k_functions.js";
import axios from "axios";

class AddPreco extends Component {
  state = {
    aProds: [], aProdsIdx: [], 
    aUnids: [], aUnidsIdx: [],
    iSelProd: -1,  // produto atualmente selecionado
    iSelUnid: -1  // unidade atualmente selecionada
  };

  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Produtos/Prod_x_Unid.php",
        func_trata: "Prod_x_Unid"
      }
    })
    .then((response) => {
//      console.log(response.data);
      this.setState({ aProds: response.data.filter(el => el.id_produto > 0)});
      this.setState({ aUnids: response.data.filter(el => el.id_unidade > 0)});
    })
    .catch(function (error) { console.log(error); alert(error) })
  }
  
  // chamado ao clicar no botão de salvar
  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if ((! isValid) || (! window.confirm("Confirma o novo preço ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 
    this.setState({validated: true});
   
    // se os dados são válidos e confirmou a movimentação
    if (isValid)
    {
      // chama o programa php para salvar os dados
      let dadosPrec = {
        // produto, unidade
        prod: document.querySelector("input[type='radio'][name='bt_prod']:checked").value,
        // unid contém a posição no array aUnids ref à unidade selecionada
        unid: document.querySelector("input[type='radio'][name='bt_unid']:checked").value,
        // qtde será multiplicada pelo fator mais abaixo (aqui na declaração não deu certo)
        prec: document.querySelector("#VUni").value,
        // programa e funcao que vao salvar os dados
        prog_trata: "./Precos/PrecosSalvar.php",
        func_trata: "SalvaPreco"
      };

//      console.log(dadosPrec);
      trataAxios(dadosPrec);
    }
  };
  
  render() {
    return (
      <>
        <CabPage titulo="Inserir Preço" paginavoltar="/prelis" />
        <div className="App-body">
          <form onSubmit={this.handleSubmit}>
            <Row><Col>
            <ul className="btn-prod">
              {this.state.aProds.length ? 
                  this.state.aProds.map((oProd, index) => (
                    <li key={index}><input required type="radio" id={"p" + index} value={oProd.id_produto} 
                      name="bt_prod" onClick={(e) => {this.setState({iSelProd: index})}}/>
                    <label htmlFor={"p"+index}>{oProd.desc_produto}</label></li>
                  )) : "No Data"}
            </ul>
            </Col></Row>
            <Separador />
            <Row><Col>
              <ul className="btn-unid">
                {this.state.aUnids.length ? 
                    this.state.aUnids.map((oUnid, index) => (
                      <li key={index}><input required type="radio" id={"u" + index} value={oUnid.id_unidade} 
                        name="bt_unid" onClick={(e) => {this.setState({iSelUnid: index})}}/>
                      <label htmlFor={"u"+index}>{oUnid.desc_unidade}</label></li>
                    )) : "No Data"}
              </ul>
            </Col></Row>
            <Separador />
            <Row>
              <Col/>
              <Col>
                <FloatingLabel controlId="VUni" label="$ Unit.">
                  <Form.Control type="number" min="0.01" step=".01"
                    onFocus={(e) => {e.target.select()}} 
                  />
                </FloatingLabel>
              </Col>
              <Col/>
            </Row>
            <br/><center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Adicionar preço
              </Button>
            </center>
          </form>
        </div>
      </>
    );
  }
}

export default AddPreco;