import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
import { FormataValor, trataAxios, SelectTipoCli } from "../k_functions";

class ClientesImportar extends Component {

  // chamado ao clicar no botão de importar
  handleSubmit = (event) => {
    let isConfirmed = true;
    let oDadosCli = {};

    if (! window.confirm("Confirma a importação ?"))
    {
      event.preventDefault();
      event.stopPropagation();
      isConfirmed = false;
    } 
   
    // se os dados são válidos e confirmou a importação
    if (isConfirmed)
    {
      // chama o programa php para atualizar o cadastro de clientes
      oDadosCli = {
        // programa e funcao que vao salvar os dados
        prog_trata: "./Clientes/ClientesImportar.php",
        func_trata: "ImportaCliente"
      };

//      console.log(oDadosCli);
      alert("Os clientes serão importados em background...")
      trataAxios(oDadosCli);
    }
  };

  render() { 
    return (
      <>
        <CabPage titulo="Importar Clientes" subtitulo="Importa Clientes do RedID" classSubTit="sFonte6Branco" paginavoltar="/home" />
        <div className="App-body">
          <form onSubmit={this.handleSubmit} action="/clilis">
            <br/><br/><br/><br/><br/>
            <center>
              <Button as="button" type="submit" id="bt_upd" 
                variant="success" size="lg">Importar clientes
              </Button>
            </center>
          </form>
        </div>
      </>
    );
  }
}

export default ClientesImportar;