import React from "react";
import Nav from "react-bootstrap/esm/Nav";
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';

const CabPage = ({titulo, subtitulo, paginavoltar, classSubTit}) => {
  return (
      <div className="App-header">
        { /* se não estiver na página de login (CTC), mostra o botão 
             voltar do lado esquerdo */ }
        { titulo !== "CTC" && ( 
            <Nav activeKey="/home">
                <Nav.Link href={paginavoltar}>
                  <i className="bi bi-arrow-left-circle to-left bt-top"></i>
                </Nav.Link>
            </Nav>
          )
        }
        <div className="TitCabPag">{ titulo }</div>
        { /* se não estiver no home nem na página de login (CTC), mostra o botão 
             home do lado direito */ }
        { titulo !== "Home" && titulo !== "CTC" && (
            <Nav activeKey="/home">
                <Nav.Link href="/home">
                  <i className="bi bi-house to-right bt-top"></i>
                </Nav.Link>
            </Nav>
          )
        }
        { subtitulo && (
          <div id="SubTitPage" className={classSubTit !== "" ? classSubTit : "SubTitCabPag"}>{ subtitulo }</div> )
        }
      </div>
   );
}

export default CabPage;