import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Form from 'react-bootstrap/Form';
import { FormataValor } from "../k_functions.js";

class VenderTot extends Component {
  render() {
    return (
      <>
        <Row>
          <Col>
            <FloatingLabel controlId="floatingItens" label="Iten(s)">
              <Form.Control type="number" value={this.props.aCarrinho.aItens.length} disabled/>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel controlId="floatingTotal" label="SubTotal">
              <Form.Control type="number" value={FormataValor(this.props.aCarrinho.nTotAcc, "input")} disabled/>
            </FloatingLabel>
          </Col>
        </Row>
      </>
    );
  }
}

export default VenderTot;