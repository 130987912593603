import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';

class ChecarVendasResult extends Component {
  state = {
    aVendas: [],  // contém a lista de vendas em análise
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
//    console.log(this.props);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasChecar.php",
        func_trata: "VendasChecar",
        ven_ini: this.props.vIni,
        ven_fim: this.props.vFim,
      }
    })
    .then((response) => {
      // console.log(response.data);
      this.setState({ aVendas: response.data });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    return (
      <>
        <CabPage titulo="Checar Vendas" subtitulo="NE: Não encontrado; EXC: Excluído pelo usuário" paginavoltar="/venchk" classSubTit="sFonte6Branco"/>
        {(this.state.aVendas.msg === 'ERRO' || this.state.aVendas.length === 0 || this.state.aVendas.length === undefined) &&
          <Alert variant="warning">Não há vendas para listar ou máximo de 300 vendas excedido !</Alert>
        }
        {this.state.aVendas.length > 0 &&
          <>
          <Table striped bordered hover>
            <thead>
              <tr align='center'>
                <th width="30%">#Venda</th>
                <th width="20%">Capa</th>
                <th width="20%">Linhas</th>
                <th width="20%">Parcelas</th>
              </tr>
            </thead>
            <tbody>
            {this.state.aVendas.map((oVenda, index) => (
              <tr key={index} align='center'>
                <td>{oVenda.id_venda}</td>
                <td style={{backgroundColor: oVenda.Cap === "NE" ? "red" : (oVenda.Cap === "EXC" ? "yellow" : "")}}>{oVenda.Cap}</td>
                <td style={{backgroundColor: oVenda.Lin === "NE" ? "red" : (oVenda.Lin === "EXC" ? "yellow" : "")}}>{oVenda.Lin}</td>
                <td style={{backgroundColor: oVenda.Par === "NE" ? "red" : (oVenda.Par === "EXC" ? "yellow" : "")}}>{oVenda.Par}</td>
              </tr>
            ))}
            </tbody>
          </Table>
          <i>Registros listados: {this.state.aVendas.length}</i>
          </>
        }
      </>
    );
  }
}

export default ChecarVendasResult;