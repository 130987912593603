import React, { Component } from "react";
import CabPage from "../CabPage.js";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/esm/Button";
// import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";

class ChecarVendas extends Component {
  state = {
    validated: false,
    vIni: 10000,   // venda inicial
    vFim: 10100,   // venda final
  };

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if (! isValid)
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 

    // se os dados são válidos 
    if (isValid)
      this.setState({validated: true});
  };

  handleChange_vIni = () => e => {  
      let vIni = e.target.value;
      this.setState({ vIni });
  }; 

  handleChange_vFim = () => e => {  
    let vFim = e.target.value;
    this.setState({ vFim });
  };

  render() {
    return (
      <>
        <CabPage titulo="Checar Vendas" paginavoltar="/home" />
        <div className="App-body">
          <form validated={this.validated} onSubmit={this.handleSubmit} action="/venchkres">
            <Row>
              <Col>
                <FloatingLabel controlId="floatingVenIni" label="Venda Inicial">
                  <Form.Control required type="number" name="VenIni" min="1" onChange={this.handleChange_vIni} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingVenFim" label="Venda Final">
                  <Form.Control required type="number" name="VenFim" min="1" onChange={this.handleChange_vFim} />
                </FloatingLabel>
              </Col>
            </Row>
            <i className="sFonte7Verde">
              6700: venda a partir da qual o CTC começou a registrar as exclusões feitas pelos usuários.<br/>
              O check aceita no máximo 300 vendas de cada vez.
            </i><br/><br/>
            <center>
              <Button as="button" type="submit" id="bt_add" 
                variant="success" size="lg">Checar
              </Button>
            </center>
          </form>
        </div>
      </>
    )
  }
}

export default ChecarVendas;