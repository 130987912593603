import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import VenderTot from "./VenderTot";
import Button from "react-bootstrap/esm/Button";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { FormataValor, Separador, trataAxios, CurDate, SelectFormasPag } from "../k_functions.js";

class VenderPag extends Component {
  state = {
    validated: false,
    perc_desconto: 0,
    desconto: 0.00,
    vFinal: -1, // atua como um flag na 1a vez; isso foi feito pq precisa de um click em algum lugar para iniciar o valor com o total do carrinho
//    aParcelas: [],
    aParcelas: this.props.aParcelas,
    observacao: "",
    cGeraNF: "N",
    sCPF: "",
    action: "/venlisres",
    dDataVenda: CurDate("YMD")
  }

  async componentDidMount() {
  }

  // a cada mudança em algum componente, chama esta função
  async componentDidUpdate(prevProps, prevState, snapshot) {
    // sempre que muda o cliente ou altera a qtd de itens do carrinho, reinicializa o % de desconto 
    // com aquele que veio do cadastro de cliente
    // a parte do || pra frente foi adicionada em 04/03/2024
    if ((prevProps.aCarrinho.nCliente !== this.props.aCarrinho.nCliente) ||
        (prevProps.aCarrinho.aItens.length !== this.props.aCarrinho.aItens.length))
    {
      this.setState({aParcelas: []});
      this.setState({perc_desconto: this.props.aCarrinho.nPercDescon});
      let desconto = (this.props.aCarrinho.nTotAcc * (this.props.aCarrinho.nPercDescon / 100)).toFixed(2);
      this.setState({ desconto });
      let vFinal = (this.props.aCarrinho.nTotAcc - desconto).toFixed(2);
      this.setState({ vFinal });
    }
  }

  // estas funções foram obtidas em https://codesandbox.io/s/3vk7jxv69p?file=/src/index.js:2651-2668
  handleChangeParc = idx => e => {
    const { name, value } = e.target;
/*    
    console.log (e.target);
    console.log (e);
    console.log (name);
    console.log (value);
*/    
    // cria uma nova linha como cópia da atual
    let parcela = this.state.aParcelas[idx];
    // altera na nova linha o valor do campo editado pelo usuário
    parcela = {...parcela, [name]: value};
    // cria uma cópia das linhas q estão em state
    let aParcelas = [...this.state.aParcelas];
    // substitiu a linha idx no novo array parcelas pela linha atualizada
    aParcelas[idx] = parcela;
    // atualiza o parcelas do state
    this.setState({
      aParcelas
    });
  };

  handleChangePercDesc = () => e => {  
    //    console.log (e);
    let perc_desconto = e.target.value;
    this.setState({ perc_desconto });
    let desconto = (this.props.aCarrinho.nTotAcc * (perc_desconto / 100)).toFixed(2);
//    console.log(desconto);
    this.setState({ desconto });
    let vFinal = (this.props.aCarrinho.nTotAcc - desconto).toFixed(2);
//    console.log(vFinal);
    this.setState({ vFinal });
  };
  
  handleChangeDesc = () => e => {  
    //    console.log (e);
    let desconto = e.target.value;
    this.setState({ desconto });
    let vFinal = this.props.aCarrinho.nTotAcc - desconto;
    this.setState({ vFinal });
  };
    
  handleChangeObs = () => e => {  
//    console.log (e);
    let observacao = e.target.value;
    this.setState({ observacao });
  };

  handleChangeCPF = () => e => {  
//    console.log (e);
    let sCPF = e.target.value;
    this.setState({ sCPF });
  };

  handleChangeDatVen = () => e => {  
    //    console.log (e);
        let dDataVenda = e.target.value;
        this.setState({ dDataVenda });
  }; 

  handleChangegNF = () => e => {  
//    console.log (e.target.checked);
    let cGeraNF = (e.target.checked ? "S" : "N");
    this.setState({ cGeraNF });
  };

  handleChangegQuitarAuto = () => e => {  
  //    console.log (e.target.checked);
      let cQuitarAuto = (e.target.checked ? "S" : "N");
      this.setState({ cQuitarAuto });
    };
    
  // recalcula o valor das parcelas após adicionar ou excluir parcelas
  recalculaParcela = (aParcelasNew) => {
    let nValParc = Math.trunc(this.state.vFinal / aParcelasNew.length * 100) / 100;
    let nSoma = 0, i;
    for (i=0; i<aParcelasNew.length;i++)
    {
      aParcelasNew[i].valor = nValParc.toFixed(2);
      if (i > 0) // soma a partir da 2a parcela para, ao final, jogar a diferença para a 1a parcela
        nSoma += nValParc;
    }
    aParcelasNew[0].valor = (this.state.vFinal - nSoma).toFixed(2);
    this.setState({
      aParcelas: aParcelasNew
    });
  }
    
  handleAddparcela = () => {
//    console.log("add")
    const oParcela = {
      valor: 0, 
      forma: "1",
      vencimento: CurDate("YMD")
    };
    // estará com -1 se não clicou no addparcela ainda nenhuma vez
    if (this.state.vFinal === -1)
//      this.setState({vFinal: this.props.aCarrinho.nTotAcc});
// tentei usar o setstate acima, mas ele não atualiza o valor... tive q atualizar diretamente abaixo
       this.state.vFinal = this.props.aCarrinho.nTotAcc;
      // cria um novo array com os dados atuais das parcelas, e adiciona a nova parcela
    // depois, recalcula todos os valores das parcelas para que cada parcela tenha o mesmo valor
    // a diferença de centavos é jogada na 1a parcela
    let aParcelasNew = [...this.state.aParcelas, oParcela];
    this.recalculaParcela(aParcelasNew);
  };

    handleShowparcelas = () => {
//    console.log("show")
//    console.log("show")
//    console.log(this.state.aParcelas);
  };

  handleRemoveSpecificparcela = (idx) => {
    let aParcelasNew = [...this.state.aParcelas]
    aParcelasNew.splice(idx, 1)
    this.recalculaParcela(aParcelasNew);
  }

  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let soma = 0;
    this.state.aParcelas.map((parcela, idx) => (soma += parcela.valor * 1));
    soma = soma * 100;
    soma = Math.round(soma);
    soma = soma / 100;
    
    /*
    console.log(soma);
    console.log(this.props.aCarrinho.nTotAcc);
    console.log(this.state.vFinal);
    */

    let isValid = ! (form.checkValidity() === false);
// csr20240206    if (soma - this.state.vFinal > 0.00)
    if (Math.abs(soma - this.state.vFinal) > 0.00)  // csr20240206
    {
      isValid = false;
      alert("A soma das parcelas (" + soma + ") não bate com o total (" + (this.state.vFinal === -1 ? this.props.aCarrinho.nTotAcc : this.state.vFinal) + ").")
    }
    if ((! isValid) || (! window.confirm("Confirma a venda ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 
    this.setState({validated: true});

//    console.log(this.props);

    // se os dados são válidos e confirmou a venda
    if (isValid)
    {
      // bloqueia botão para não clicar de novo
      document.querySelector("#bt_end").innerHTML = "Aguarde...";
      document.querySelector("#bt_end").classList.remove("btn-success");
      document.querySelector("#bt_end").classList.add("btn-warning");
      document.querySelector("#bt_end").disabled = true;

      // chama o programa php para salvar os dados
      const dadosVen = {
        vsub: this.props.aCarrinho.nTotAcc,
        vdes: this.state.desconto,
        vtot: this.state.vFinal,
        ncli: this.props.aCarrinho.nCliente,
        nusu: localStorage.getItem("user"), // this.props.nUsuario,
        obse: this.state.observacao,
        sCPF: this.state.sCPF,
        bgNF: this.state.cGeraNF,
        bQui: this.state.cQuitarAuto,
        dVen: this.state.dDataVenda,  // data da venda
        itens: JSON.stringify(this.props.aCarrinho.aItens),
        parcs: JSON.stringify(this.state.aParcelas),
        prog_trata: "./Vendas/VendasSalvar.php",
        func_trata: "SalvaVenda"
      }
//      console.log(dadosVen.parcs);
//      let oResponse = trataAxios(dadosVen);
        trataAxios(dadosVen);
//      this.setState({action: "/venlis/" + oResponse.id_venda});
    }
  };

  render() {
    return (
      <>
        <div className="App-body">
          <VenderTot aCarrinho={this.props.aCarrinho}/>
          <Separador />
          <form validated={this.validated} onSubmit={this.handleSubmit} action={this.state.action}>
            <div className="carrinho">
              {this.props.aCarrinho.aItens.length === 0 && 
                <Alert variant="warning">Carrinho vazio !</Alert>
              }
              {this.props.aCarrinho.aItens.length > 0 && (
                <>
                  <Row>
                    <Col>
                      <FloatingLabel controlId="floatingPercDesconto" label="% Desconto">
                        <Form.Control required type="number" name="valor" min="0,01" max={100}
                                    value={this.state.perc_desconto}
                                    onClick={this.handleChangePercDesc()}
                                    onChange={this.handleChangePercDesc()}
                        />
                        <Form.Control.Feedback>OK!</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="floatingDesconto" label="Desconto">
{/*
                        <Form.Control required type="number" name="valor" min="0,01" max={this.props.aCarrinho.nTotAcc}
                                    value={this.state.desconto}
                                    onChange={this.handleChangeDesc()}
                        />
*/}                        
                        <Form.Control type="number" name="valor" value={this.state.desconto} disabled />
                        <Form.Control.Feedback>OK!</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="floatingTotal" label="Valor Total">
                      <Form.Control type="number" value={FormataValor((this.state.vFinal === -1 ? 
                          this.props.aCarrinho.nTotAcc : this.state.vFinal), "input")} disabled/>                         
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Separador />
                  <Row>
                    <Col>
                      <FloatingLabel controlId="floatingDataVenda" label="Data Venda">
                        <Form.Control required type="date" name="dataVenda" 
                          value={this.state.dDataVenda} 
                          onChange={this.handleChangeDatVen()}
                        />
                        <Form.Control.Feedback>OK!</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col>
                    <i className="sFonte7Verde">Obs: Movimentações de estoque permanecerão na data atual.</i>
                    </Col>
                  </Row>
                  <Separador />
                  <Table striped bordered hover>
                    <thead>
                        <tr align='center'>
                            <th width="50%">Parcela<br/>Valor {/* usado apenas para debugar
                              <Button as="button" id="bt_int" variant="success" 
                              size="sm" onClick={() => {this.handleShowparcelas()}}>?</Button> */ }
                            </th>
                            <th width="40%">Forma<br/>Vencimento</th>
                            <th width="10%"> 
                              <Button as="button" id="bt_add" variant="success" 
                                size="sm" onClick={() => {this.handleAddparcela()}}>+</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.aParcelas.map((parcela, idx) => (
                          <tr key={idx} align='center'>
                              <td><b className="txt_destac">{idx+1}/{this.state.aParcelas.length}</b><br/>
                                <Form.Group as={Col} md="4" controlId={"validationCustom" + idx}>
                                  <Form.Control required type="number" name="valor" min="0,01" max={this.props.aCarrinho.nTotAcc}
                                    value={this.state.aParcelas[idx].valor}
                                    onChange={this.handleChangeParc(idx)}
                                  />
                                  <Form.Control.Feedback>OK!</Form.Control.Feedback>
                                </Form.Group>                              
                              </td>
                              <td>
                                <Form.Select required defaultValue="1" name="forma" 
                                  onChange={this.handleChangeParc(idx)}>
                                  <SelectFormasPag id={"forma_" + idx}/>
                                </Form.Select>
                                <Form.Control required type="date" name="vencimento" min="2022-11-01"
                                    value={this.state.aParcelas[idx].vencimento}
                                    onChange={this.handleChangeParc(idx)}
                                  />
                              </td>
                              <td>
                              { idx !== 0 && (
                                <Button as="button" id={"bt_del" + idx} variant="danger" 
                                  size="sm" onClick={() => {this.handleRemoveSpecificparcela(idx)}}>-</Button> )
                              }
                              </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <center>
                    <Form.Group controlId={"validationCustomObs"}>
                      <Form.Control as="textarea" parcelas={3} placeholder="Observacao"
                        value={this.state.observacao}
                        onChange={this.handleChangeObs()}
                        />
                    </Form.Group><br/>
                    <Row>
                      <Col>
                          <Form.Check inline type="switch" label="Quitar" 
                            value={this.state.cQuitarAuto}
                            onChange={this.handleChangegQuitarAuto()}
                          />
                      </Col>
                      <Col>
                          <Form.Check inline type="switch" label="CTC" 
                            value={this.state.cGeraNF}
                            onChange={this.handleChangegNF()}
                          />
                      </Col>
                      {this.props.aCarrinho.cTipoCli === "C" &&
                        <Col xs={7}>
                          <FloatingLabel controlId="floatingCPF" label="CPF">
                            <Form.Control type="text" 
                              value={this.state.sCPF}
                              onChange={this.handleChangeCPF()}                    
                            />
                          </FloatingLabel>
                        </Col>
                      }
                    </Row><br/>
                    <Button as="button" type="submit" id="bt_end" 
                      variant="success" size="lg">Finalizar venda
                    </Button>
                  </center>
                </>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default VenderPag;