import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";

class FormasPagListar extends Component {
  state = {
    aFormas: []
  };

  /* requisição com axios para ler lista de formas de pagamento */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./FormasPag/FormasPagListar.php",
        func_trata: "FormasPagListar"
      }
    })
      .then((response) => {
        this.setState({ aFormas: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  render() {
    return (
      <>
        <CabPage titulo="Formas de Pagamento" paginavoltar="/home" />
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="20%">ID</th>
              <th width="50%">Descrição</th>
              <th width="30%">Aceita Quitação Automática</th>
            </tr>
          </thead>
          <tbody>
            {this.state.aFormas.map((oUnidade, index) => (
              <tr key={index} align='center'>
                <td>{oUnidade.id_forma}</td>
                <td>{oUnidade.desc_forma}</td>
                <td>{oUnidade.aceita_quit_aut}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <i className="sFonte7Verde">A alteração do flag de quitação automática deve ser replicada no programa que salva a venda.</i>
      </>
    );
  }
}

export default FormasPagListar;