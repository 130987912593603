import React from "react";
import { Link } from "react-router-dom";
import CabPage from "./CabPage";

const NotFound = () => (
  <>
    <CabPage titulo="CTC" />
    <div className="App-body">
      <h1>404!</h1>
      <p>
        Lamento. Essa página não existe ou foi removida <br />
        <Link to="/">Login</Link>
      </p>
    </div>
  </>
);

export default NotFound;