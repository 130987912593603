import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Button from "react-bootstrap/esm/Button";
import Nav from "react-bootstrap/esm/Nav";
import { FaBinoculars } from "react-icons/fa";

class ClientesListar extends Component {
  state = {
    aClientes: [],    // contém os clientes filtrados
    aClientesAll: []  // contém todos os clientes
  };

  /* requisição com axios para ler lista de clientes */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Clientes/ClientesListar.php",
        func_trata: "ClientesListar"
      }
    })
      .then((response) => {
        // console.log(response.data);
        this.setState({ aClientes: response.data });
        this.setState({ aClientesAll: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  // filtra clientes conforme usuário vai digitando no campo filtrar
  // 09/11/24: colocada a função toString no nome e na fantasia, pois quando estes campos continham só números, a aplicação direta do toLowerCase dava erro
  handleLocalizar = (e) => {
//    console.log(this.state.aClientesAll);
    const aClientes = this.state.aClientesAll.filter(el => 
      ((el.nome_razao.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.fantasia.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ||
       (el.id_cliente.toString().indexOf(e.target.value) > -1)));
    this.setState({aClientes: aClientes});
  }

  render() {
// csr20240206    let acessofull = localStorage.getItem("acessofull");
//    console.log(acessofull);
    return (
      <>
        <CabPage titulo="Clientes" classSubTit="sFonte6Branco" paginavoltar="/home" />
        <form>
          <input className="form-control mr-sm-2" type="search" placeholder="Localizar cliente" aria-label="Search" onChange={this.handleLocalizar} />
        </form>
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="15%">ID<br/><i className='sRazaoTelCli'>Ativo?<br/>Pessoa</i></th>
              <th width="70%">Fantasia<br/><br/><i className='sRazaoTelCli'>Razão Social</i></th>
              <th width="15%">
              {// csr20240206 acessofull === 'S' &&
                  <Nav activeKey="/cliadd">
                    <Nav.Link href={`/cliadd`}>
                    <Button as="button" id="bt_add" variant="success" size="sm">+</Button>
                    </Nav.Link>
                  </Nav>
              }
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.aClientes.map((oCliente, index) => (
              <tr key={index} align='center'>
                <td><b>{oCliente.id_cliente}</b><br/><i className='sRazaoTelCli'>{oCliente.cliente_ativo} / {oCliente.tipo_cliente}</i></td>
                <td><b>{oCliente.fantasia}</b><br/><i className='sRazaoTelCli'>{oCliente.nome_razao}</i></td>
                <td>
                    <Nav.Link href={`/clidet/${oCliente.id_cliente}`}>
                      <FaBinoculars />
                    </Nav.Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default ClientesListar;