import React, { Component } from "react";
import CabPage from "../CabPage";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Button from "react-bootstrap/esm/Button";
// import axios from "axios";
import { GetArrTransEst, Separador, ShowUnids, LeProdUnidPrec, trataAxios } from "../k_functions.js";

const aTransManual = GetArrTransEst().filter(p => p.tipo === "manual");

class ProdDest extends Component {
  state = {
    iSelProdD: -1        // index dentro de aProds do produto destino selecionado
  }

  // seta o produto selecionado, que sera usado para filtrar os produtos destino no caso de transformações
  setaProdD = (e) => {  
    this.setState({iSelProdD: Number(e.target.value)});
  }
  
  render() {
    /*
    console.log(this.props.iSelProdO);    
    console.log(this.props.nSelProdO);
    console.log(this.props.aProds);
    if (this.props.nSelProdO > -1)
    {
      console.log(this.props.aProds[this.props.iSelProdO].orig.split(";"));
      console.log(this.props.aProds[this.props.iSelProdO].orig.split(";").includes(this.props.nSelProdO));
      console.log(this.props.aProds[this.props.iSelProdO].orig.split(";").includes(this.props.iSelProdO));
      console.log(this.props.aProds.filter(p => p.orig.split(";").includes(this.props.nSelProdO)));
    } 
*/
    return (
      <>
        <Separador />
        <div className="label">Produto Destino</div>
        <Row><Col>
          <ul className="btn-prod">
            {this.props.aProds.filter(p => p.orig.split(";").includes(this.props.nSelProdO)).map((oProd, index) => (
              <li key={index}><input required type="radio" id={"pD" + index} value={oProd.idx} name="bt_prodD"
                onClick={(e) => {this.setaProdD(e)}}/>
              <label htmlFor={"pD"+index}>{oProd.desc}</label></li>
            ))}
          </ul>
        </Col></Row>
        <Separador />
        <Row>
          <Col xs={9}>
          { <ShowUnids aProds={this.props.aProds} aUnids={this.props.aUnids} iSelProd={this.state.iSelProdD} cSufixo={"D"} /> }
          { /*
            <ul className="btn-unid">
              {this.props.aUnids.map((oUnid, index) => (
                <li key={index}><input required type="radio" id={"uD"+index} value={index} name="bt_unidD" />
                <label htmlFor={"uD"+index}>{oUnid.desc}</label></li>
              ))}
            </ul>
          */ }
          </Col>
          <Col>
            <FloatingLabel controlId="QtdDest" label="Qtd">
                <Form.Control required type="number" step=".01" 
                  onFocus={(e) => {e.target.select()}} 
                />
              </FloatingLabel>
          </Col>
        </Row>
      </>
    )
  }
}

class MovimentarEstoque extends Component {
  state = {
    aProds: [], aProdsIdx: [], 
    aUnids: [], aUnidsIdx: [], 
    bEhTransf: false,    // indica se é transformação de produto
    bTransSet: false,    // indica se escolheu a transação
    iSelProdO: -1,        // index dentro de aProds do produto origem selecionado
    nSelProdO: "-1"       // codigo (string) do produto origem selecionado
  }

  // le dados de produtos, unidades e tipos de movimentação
  async componentDidMount() {
    let oPUP = LeProdUnidPrec();
    this.setState({aProds: oPUP.aProds, aUnids: oPUP.aUnids});
  } 

  // chamado ao clicar no botão de salvar
  handleSubmit = (event) => {
    // primeiro valida o formulario
    const form = event.currentTarget;
    let isValid = ! (form.checkValidity() === false);
    if ((! isValid) || (! window.confirm("Confirma a movimentação ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    } 
    this.setState({validated: true});
   
    // se os dados são válidos e confirmou a movimentação
    if (isValid)
    {
      // chama o programa php para salvar os dados
      let dadosMovEst = {
        // tran contém o código da transação de estoque
        tran: document.querySelector("input[type='radio'][name='bt_trans']:checked").value,

        // produto, unidade e quantidade origem
        // prod contém a posição no array aProds ref ao produto selecionado
        prodO: document.querySelector("input[type='radio'][name='bt_prodO']:checked").value,
        // codp é o codigo no banco de dados do produto selecionado e será atribuído a seguir
        codpO: null,
        // unid contém a posição no array aUnids ref à unidade selecionada
        unidO: document.querySelector("input[type='radio'][name='bt_unidO']:checked").value,
        // codu é o codigo no banco de dados da unidade selecionada e será atribuído a seguir
        coduO: null,
        // qtde será multiplicada pelo fator mais abaixo (aqui na declaração não deu certo)
        qtdeO: document.querySelector("#QtdOrig").value,

        // produto, unidade e quantidade destino
        // apenas cria os atributos zerados; valores serão atribuidos abaixo condicionalmente
        prodD: null,
        codpD: null,
        unidD: null,
        coduD: null,
        qtdeD: 0,

        // usuario
        nusu: localStorage.getItem("user"), // 1
        // programa e funcao que vao salvar os dados
        prog_trata: "./Estoque/MovEstSalvar.php",
        func_trata: "SalvaMovEst"
      };

//      console.log(dadosMovEst);

      // multiplica a quantidade pelo fator associado ao tipo da transação de estoque
      dadosMovEst.qtdeO = dadosMovEst.qtdeO * 
        GetArrTransEst().filter(p => p.cod === dadosMovEst.tran)[0].fator;

      // atribuição do código do item e cod da unidade de medida origens
      dadosMovEst.codpO = this.state.aProds[dadosMovEst.prodO].cod;
      dadosMovEst.coduO = this.state.aUnids[dadosMovEst.unidO].cod;

      // produto, unidade e quantidade destino
      // apenas se tran == TRA
      if (dadosMovEst.tran === "TRA")
      {
        dadosMovEst.prodD = document.querySelector("input[type='radio'][name='bt_prodD']:checked").value;
        dadosMovEst.codpD = this.state.aProds[dadosMovEst.prodD].cod;
        dadosMovEst.unidD = document.querySelector("input[type='radio'][name='bt_unidD']:checked").value;
        dadosMovEst.coduD = this.state.aUnids[dadosMovEst.unidD].cod;
        dadosMovEst.qtdeD = document.querySelector("#QtdDest").value * 
          GetArrTransEst().filter(p => p.cod === dadosMovEst.tran)[0].fator;
      }

//      console.log(dadosMovEst);
//      console.log(dadosMovEst.prodD);
      
      trataAxios(dadosMovEst);
    }
  };

  // exibe ou não a parte da tela para lançar qtd e unidade de medida destino
  setaDest = (e) => {  
    this.setState({bEhTransf : e.target.id === "t_TRA"});
    this.setState({bTransSet : true});
  }

  // seta o produto selecionado, que sera usado para filtrar os produtos destino no caso de transformações
  setaProdO = (e) => {  
    this.setState({iSelProdO: Number(e.target.value)});
    this.setState({nSelProdO: this.state.aProds[Number(e.target.value)].cod.toString()});
  }

  render() {
    return (
      <>
        <CabPage titulo="Movimentar" paginavoltar="/home" />
        <div className="App-body">
          <form onSubmit={this.handleSubmit}>
            <div className="label">Transação</div>
            <Row><Col>
              <ul className="btn-trans">
                {aTransManual.map((oTrans, index) => (
                  <li key={index}><input required type="radio" id={"t_"+oTrans.cod} value={oTrans.cod} name="bt_trans" 
                      onClick={(e) => {this.setaDest(e)}}/>
                  <label htmlFor={"t_"+oTrans.cod}>{oTrans.desc}</label></li>
                ))}
              </ul>
            </Col></Row>
            {this.state.bTransSet && (
              <>
              <Separador />
              <div className="label">Produto Origem</div>
              <Row><Col>
                <ul className="btn-prod">
                  {this.state.aProds.map((oProd, index) => (
                    <li key={index}><input required type="radio" id={"pO" + index} value={index} name="bt_prodO"
                        onClick={(e) => {this.setaProdO(e)}}/>
                    <label htmlFor={"pO"+index}>{oProd.desc}</label></li>
                  ))}
                </ul>
              </Col></Row>
              <Separador />
              <Row>
                <Col xs={9}>
                  { <ShowUnids aProds={this.state.aProds} aUnids={this.state.aUnids} iSelProd={this.state.iSelProdO} cSufixo={"O"} /> }
                </Col>
                <Col>
                  <FloatingLabel controlId="QtdOrig" label="Qtd">
                    <Form.Control required type="number" step=".01"
                      onFocus={(e) => {e.target.select()}} 
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              </>
            )}
            {this.state.bEhTransf && (
              <>
                <ProdDest aProds={this.state.aProds} aUnids={this.state.aUnids} nSelProdO={this.state.nSelProdO} iSelProdO={this.state.iSelProdO} cTrans={"M"} />
              </>
            )}
            <Separador />
            <center>
              <Button as="button" type="submit" id="bt_slv" 
                variant="success" size="lg">Salvar
              </Button>
            </center>
          </form>
        </div>
      </>
    );
  }
}

export default MovimentarEstoque;