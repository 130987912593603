import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form';
// import { BsPencilFill } from "react-icons/bs"
import { FaPencilAlt } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { FormataValor, FormataData, trataAxios } from "../k_functions.js";
import Alert from 'react-bootstrap/Alert';
import Nav from "react-bootstrap/esm/Nav";

class VendasDetalhe extends Component {
  state = {
    aDetVenNew: [], 
    aDetParcelas: [],
    bParcelasQuitadas: false
  };

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasDetalhe.php",
        func_trata: "VendasDetalhe",
        id_venda: this.props.idVenda,
        capa_e_linha: "Y"
      }
    })
    .then((response) => {
      this.setState({ aDetVenNew: response.data["venda"] });
      this.setState({ aDetParcelas: response.data["parcelas"] });
      const aParcQui = this.state.aDetParcelas.filter(el => (el.dt_pgto != null));
      this.setState({ bParcelasQuitadas: aParcQui.length > 0})
//      console.log(aParcQui);
//      console.log(response.data);
    })
    .catch(function (error) { console.log(error); alert(error) })
  }

  // chamado ao clicar no botão de eliminar
  handleSubmit = (event) => {
    let isConfirmed = true;

    if (! window.confirm("Confirma a exclusão da venda " + this.props.idVenda + " ?\n\n" + 
      "********** ATENÇÃO **********\n\n" + 
      "Ao CONFIRMAR A EXCLUSÃO, não só a venda será excluída, mas também as movimentações de estoque " + 
      "(mesmo que as quantidades já tenham sido retiradas), " +
      "as parcelas da venda (mesmo as quitadas), e o saldo de estoque voltará ao que estava antes da venda. "))
    {
      event.preventDefault();
      event.stopPropagation();
      isConfirmed = false;
    } 
   
    // se os dados são válidos e confirmou a movimentação
    if (isConfirmed)
    {
      // chama o programa php para excluir o preço
      let oDadosVen = {
        id_venda: this.props.idVenda,
        nusu: localStorage.getItem("user"),
        // programa e funcao que vao salvar os dados
        prog_trata: "./Vendas/VendasExcluir.php",
        func_trata: "ExcluiVenda"
      };

      //      console.log(oDadosVen);
      trataAxios(oDadosVen);
    }
  };

  render() {
    return (
      <>
      <CabPage titulo="Consultar Venda" paginavoltar="/venlis" />
        <div className="App-body">
          <Row>
            <Col xs="3">
              <FloatingLabel controlId="floatingVendas" label="Venda">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? this.state.aDetVenNew[0].id_venda : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingUsuario" label="Usuário">
                  <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? this.state.aDetVenNew[0].login : ""} disabled/>
              </FloatingLabel>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FloatingLabel controlId="floatingCliente" label="Cliente">
                  <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? this.state.aDetVenNew[0].fantasia + 
                      " (" + this.state.aDetVenNew[0].nome_razao + ")" : ""} disabled/>
              </FloatingLabel>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <FloatingLabel controlId="floatingData" label="Data (lan)">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? FormataData(this.state.aDetVenNew[0].data_lan_venda) : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingHora" label="Hora (lan)">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? this.state.aDetVenNew[0].hora_lan_venda : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingData" label="Data (ven)">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? FormataData(this.state.aDetVenNew[0].data_venda) : ""} disabled/>
              </FloatingLabel>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <FloatingLabel controlId="floatingSubtotal" label="Subtotal">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? FormataValor(this.state.aDetVenNew[0].valor_subtotal,"") : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingDesconto" label="Desconto">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? FormataValor(this.state.aDetVenNew[0].valor_desconto,"") : ""} disabled/>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingTotal" label="Total">
                <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? FormataValor(this.state.aDetVenNew[0].valor_total,"") : ""} disabled/>
              </FloatingLabel>
            </Col>
          </Row>
          <br/>
          {this.state.bParcelasQuitadas && <i className="sFonte7Verde">Apenas vendas sem nenhuma parcela quitada permitem alterar itens.</i>}
          <Table striped bordered hover size="sm">
            <thead>
              <tr align='center'>
                <th width="30%">Produto</th>
                <th width="10%">Qtd</th>
                <th width="20%">Und</th>
                <th width="15%">$ Unit</th>
                <th width="15%">$ Total</th>
                <th width="10%">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {this.state.aDetVenNew.map((oDetVenda, index) => (
                <tr key={index} align='center' valign='middle'>
                  <td>{oDetVenda.desc_produto}</td>
                  <td>{oDetVenda.qtd_vendida}</td>
                  <td>{oDetVenda.desc_unidade}</td>
                  <td>{FormataValor(oDetVenda.valor_unit, "")}</td>
                  <td>{FormataValor(oDetVenda.qtd_vendida*oDetVenda.valor_unit)}</td>
                  <td>
                  {! this.state.bParcelasQuitadas && oDetVenda.qtd_vendida > 0 &&
                    <Nav activeKey="/venalt">
                      <Nav.Link href={`/venalt/${oDetVenda.id_venda_lin}`}>
                        <FaPencilAlt />
                      </Nav.Link>
                    </Nav>
                  }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {! this.state.bParcelasQuitadas && <i className="sFonte7Verde">Ao alterar itens, as parcelas são calculadas proporcionalmente conforme venda original.</i>}
          <Table striped bordered hover size="sm">
            <thead>
              <tr align='center'>
                <th width="20%">Parcela</th>
                <th width="20%">Valor</th>
                <th width="20%">Forma</th>
                <th width="20%">Venc</th>
                <th width="20%">Pgto</th>
              </tr>
            </thead>
            <tbody>
              {this.state.aDetParcelas.map((oDetParcelas, index) => (
                <tr key={index} align='center'>
                  <td>{oDetParcelas.num_parcela}</td>
                  <td>{FormataValor(oDetParcelas.valor_parcela, "")}</td>
                  <td>{oDetParcelas.desc_forma}</td>
                  <td>{FormataData(oDetParcelas.data_vencim)}</td>
                  <td>{oDetParcelas.dt_pgto}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <FloatingLabel controlId="floatingObs" label="Observação">
            <Form.Control type="text" value={this.state.aDetVenNew.length > 0 ? this.state.aDetVenNew[0].observacao : ""} disabled/>
          </FloatingLabel>
          <br/>
          <form onSubmit={this.handleSubmit} action="/venlis">
            <center>
              <Button as="button" type="submit" id="bt_del"
                variant="danger" size="lg">Eliminar venda
              </Button>
            </center>
          </form>
          <br/>
          {this.state.bParcelasQuitadas &&
            <Alert variant="warning">ATENÇÃO: eliminar a venda eliminará também as quitações.</Alert>
          }
        </div>
      </>
    );
  }
}

export default VendasDetalhe;