import React, { Component } from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import axios from "axios";

class UnidadesListar extends Component {
  state = {
    aUnidades: []
  };

  /* requisição com axios para ler lista de unidades */
  async componentDidMount() {
    axios({
      method: 'get',
//      url: 'http://localhost:8080/React/ctc3/src/server/TrataGedtPost.php',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Unidades/UnidadesListar.php",
        func_trata: "UnidadesListar"
      }
    })
      .then((response) => {
        // console.log(response.data);
        this.setState({ aUnidades: response.data });
      })
      .catch(function (error) { console.log(error); alert(error) })
  }

  render() {
    return (
      <>
        <CabPage titulo="Unidades de Medida" paginavoltar="/home" />
        <Table striped bordered hover size="sm">
          <thead>
            <tr align='center'>
              <th width="10%">ID</th>
              <th width="30%">Abreviação</th>
              <th width="60%">Descrição</th>
            </tr>
          </thead>
          <tbody>
            {this.state.aUnidades.map((oUnidade, index) => (
              <tr key={index} align='center'>
                <td>{oUnidade.id_unidade}</td>
                <td>{oUnidade.abrev_unidade}</td>
                <td>{oUnidade.desc_unidade}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export default UnidadesListar;