import React from "react";
import logo from './img/cropped-citrus-logo.png';
import * as yup from "yup";
import { ErrorMessage, Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import CabPage from "./CabPage";
import { AppVer } from "./k_functions.js";

// login e senha para teste: clribeiro / teste

function Login (isAuthenticated) {
    const navigate = useNavigate();

    const handleLogin = (values) => {
//        alert (`${process.env.REACT_APP_API}TrataLogin.php`);
//        console.log (`${process.env.REACT_APP_API}TrataLogin.php`);
//        console.log(values.login);
        axios({
            method: 'post',
//            url: 'http://localhost:8080/ctc5/public/PHP/TrataLogin.php',
            url: `${process.env.REACT_APP_API}TrataLogin.php`,
            data: {login: values.login, senha: values.senha}
        })
        .then((response) => {
//            console.log(response.data);
            if (response.data.ret)
            {
                isAuthenticated = true;
                // https://www.freecodecamp.org/news/how-to-persist-a-logged-in-user-in-react/
                localStorage.setItem('user', response.data.ret["id_user"])
                localStorage.setItem('acessofull', response.data.ret["acessofull"])
                localStorage.setItem('usuario', values.login)
                navigate('/home');
//                navigate('/home/' + response.data.msg);
            }
            else
            {
                alert("Usuario ou senha inválido ou inativo.");
            }
        })
        .catch(function (error) {alert(error + " (check your internet connection / database connection)")})
    };
        
    const validationsLogin = yup.object().shape({
        login: yup
            .string()
            .min(5, "O login deve ter pelo menos 5 caracteres")
            .required("O login é obrigatório"),
        senha: yup
            .string()
            .min(5, "A senha deve ter pelo menos 5 caracteres")
            .required("A senha é obrigatória"),
    });
      
  return (
    <>
        <CabPage titulo="CTC" />
        <div className="App-body">
            <div className="App-login">
                <br/><br/><br/>
                <img src={logo} className="App-logo" alt="logo" /><br/><br/><br/>
                <Formik
                    initialValues={{}}
                    onSubmit={handleLogin}
                    validationSchema={validationsLogin}
                >
                <Form className="login-form">
                    <div className="input-group">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                    <Field name="login" className="form-control" placeholder="Login" />
                    <ErrorMessage
                        component="div"
                        name="login"
                        className="form-error"
                    />
                    </div>
                    <br/>
                    <div className="input-group">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-lock"></i></span>
                    <Field name="senha" type="password" className="form-control" placeholder="Senha" />
                    <ErrorMessage
                        component="div"
                        name="senha"
                        className="form-error"
                    />
                    </div><br/>
                    <Button type="submit" variant="success" size="lg" alt="href='/home'">Entrar</Button>
                </Form>
                </Formik><br/>
                <AppVer />
            </div>
        </div>
    </>
  );
}

export default Login;
